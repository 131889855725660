
@font-face {
  font-family: "Tinos";
  src: url("./assets/Tinos/Tinos-Regular.ttf");
  font-weight: 400;
  font-style: normal;
  src: url("./assets/Tinos/Tinos-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Tinos";
  src: url("./assets/Tinos/Tinos-Italic.ttf");
  font-weight: 400;
  font-style: italic;
  src: url("./assets/Tinos/Tinos-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Tinos";
  src: url("./assets/Tinos/Tinos-Bold.ttf");
  font-weight: 700;
  font-style: normal;
  src: url("./assets/Tinos/Tinos-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Tinos";
  src: url("./assets/Tinos/Tinos-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
  src: url("./assets/Tinos/Tinos-BoldItalic.ttf") format("truetype");
}
/* 
@font-face {
  font-family: "Aleo";
  src: url("./assets/Aleo/Aleo-Light.otf");
  font-weight: 300;
  font-style: normal;
  src: url("./assets/Aleo/Aleo-Light.otf") format("truetype");
}

@font-face {
  font-family: "Aleo";
  src: url("./assets/Aleo/Aleo-LightItalic.otf");
  font-weight: 300;
  font-style: italic;
  src: url("./assets/Aleo/Aleo-LightItalic.otf") format("truetype");
}

@font-face {
  font-family: "Aleo";
  src: url("./assets/Aleo/Aleo-Regular.otf");
  font-weight: 400;
  font-style: normal;
  src: url("./assets/Aleo/Aleo-Regular.otf") format("truetype");
}

@font-face {
  font-family: "Aleo";
  src: url("./assets/Aleo/Aleo-Italic.otf");
  font-weight: 400;
  font-style: italic;
  src: url("./assets/Aleo/Aleo-Italic.otf") format("truetype");
}

@font-face {
  font-family: "Aleo";
  src: url("./assets/Aleo/Aleo-Bold.otf");
  font-weight: 700;
  font-style: normal;
  src: url("./assets/Aleo/Aleo-Bold.otf") format("truetype");
}

@font-face {
  font-family: "Aleo";
  src: url("./assets/Aleo/Aleo-BoldItalic.otf");
  font-weight: 700;
  font-style: italic;
  src: url("./assets/Aleo/Aleo-BoldItalic.otf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/Slider-font/TUV-Montserrat-Regular.ttf");
  font-weight: 400;
  font-style: normal;
  src: url("./assets/Slider-font/TUV-Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/Slider-font/TUV-Montserrat-Bold.ttf");
  font-weight: 700;
  font-style: normal;
  src: url("./assets/Slider-font/TUV-Montserrat-Bold.ttf") format("truetype");
} */

/* @font-face {
  font-family: "Lobster";
  src: url("./assets/Slider-font/SVN-Lobster.ttf");
  font-weight: 400;
  font-style: normal;
  src: url("./assets/Slider-font/SVN-Lobster.ttf") format("truetype");
}  */

* {
  font-family: "Tinos", sans-serif;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  min-height: 0;
  min-width: 0;
}

html, body{
  font-size: 16px;
}

.ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items {
  flex-wrap: wrap !important;
}

a {
  text-decoration: none;
  color: #000;
}

button{
  border: none;
  outline: none;
}

img{
  max-width: 100%;
  height: auto;
}

ul li{
  list-style-type: none;
}

.border-none{
  border: none;
}

@media (min-width: 1440px){
  .MuiContainer-maxWidthXl {
    max-width: 1280px!important;
  }
}
.MuiAppBar-colorPrimary{
  background-color: #fff!important;
}

.jss2{
  background-color: #fff!important;
}

.btn-secondary{
  color: #f2c123;
  background: #fff;
  border: 1px solid #f2c123;
}

.txtDanger {
  color: #ff2323
}

.txtBold {
  font-weight: bold;
}

.mb10 {
  margin-bottom: 10px;
}

.MuiCircularProgress-colorPrimary{
  color: #fff
}

.lazy-image {
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  -webkit-transition: opacity 0.1s ease-in-out;
  -moz-transition: opacity 0.1s ease-in-out;
  object-fit: scale-down;
}

.lazy-image.opacity {
  opacity: 1;
}

.slick-slide {
  outline: none!important;
}

/* .slick-slider.slick-initialized {
  overflow: hidden;
} */

.copyRight__wrapper {
  width: 100%;
}

@media screen and (max-width: 820px) {
  .copyRight__wrapper {
    margin-top: 10px;
  }
}

.copyRight__container {
  display: block;
  text-align: right;
}

.copyRight__container p {
  color: #757F9B;
}

.copyRight__container p a {
  font-weight: bold;
  color: #757F9B;
}

.copyRight__container p a:hover {
  color: #f2c123;
}